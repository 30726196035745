<template>
  <el-row style="margin-top: 20px">
    <el-col :span="24" :xs="24">
      <div class="iframe-wrap">
        <iframe src="https://car.ganzhouii.com/web/car?type=0"></iframe>
      </div>
    </el-col>
    <el-col :span="12" :xs="24">
      <div
        style="margin-bottom: 30px; text-align: center; padding: 3px"
        id="logistics"
      ></div>
    </el-col>
    <el-col :span="12" :xs="24">
      <div
        style="margin-bottom: 30px; text-align: center; padding: 3px"
        id="freight"
      ></div>
    </el-col>
  </el-row>
</template>

<script>
import { BASE_URL, FACTORY_URL, MACHINE_API } from "../config";

export default {
  data() {
    return {};
  },
  mounted() {
    this.echarts();
  },
  methods: {
    echarts() {
      window.onresize = function() {
        logistics.resize({
          window: window.innerWidth,
          // height: window.innerHeight,
        });
        freight.resize({
          window: window.innerWidth,
          // height: window.innerHeight,
        });
      };
      // window.addEventListener("resize", function() {
      //   charta.resize();
      // });
      let logistics = this.$echarts.init(document.getElementById("logistics"));
      let freight = this.$echarts.init(document.getElementById("freight"));
      logistics.setOption({
        title: {
          text: "南康家具物流指数:",
          top: "7%",
          left: "7%",
          textStyle: {
            color: "#000",
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "item",
        },
        color: ["#66ffff", "#66ff99", "#ff6699", "#33ccff"],
        legend: {
          textStyle: {
            fontSize: 10,
          },
          data: [],
        },
        xAxis: [
          {
            type: "category",
            data: [],
            // axisPointer: {
            //   type: "shadow",
            // },
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            max: 120,
            interval: 20,
            axisLabel: {
              formatter: "{value} %",
              textStyle: {
                fontSize: 10,
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: "30",
            boundaryGap: [0, 0.1],
          },
        ],
      });
      freight.setOption({
        title: {
          text: "南康家具运价指数:",
          top: "8%",
          left: "7%",
          textStyle: {
            color: "#000",
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "item",
        },
        color: ["#66ffff", "#ff6699", "#33ccff"],
        legend: {
          textStyle: {
            fontSize: 10,
          },
          data: ["木材", "成品"],
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            max: 100,
            interval: 20,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                fontSize: 10,
              },
            },
          },
        ],
        series: [
          {
            name: "木材",
            type: "line",
            boundaryGap: [0, 0.1],
            data: [],
          },
          {
            name: "成品",
            type: "line",
            boundaryGap: [0, 0.1],
            data: [],
          },
        ],
      });
      let url = `${BASE_URL}/api/home/PagingLogisticszs`;
      this.axios({
        url: url, //获取菜单列表
        method: "POST",
        data: {
          search: {
            NoticeTypeCode: "",
          },
          pageIndex: 1,
          pagesize: 200,
        },
      }).then((res) => {
        let data = res.data.content.rows.map((item) => {
          return item.logisticszsNumber;
        });
        let xdata = res.data.content.rows.map((item) => {
          return item.logisticszsTime.slice(0, 7);
        });
        let y = data.reverse();
        let x = xdata.reverse();
        console.log(y, x, "0000000000");
        logistics.setOption({
          xAxis: [{ data: x }],
          series: [{ data: y }],
        });
      });
      this.axios({
        url: `${BASE_URL}/api/home/PagingFreightrate`,
        method: "POST",
        data: {
          search: {
            isdelete: 1,
          },
          pageIndex: 1,
          pagesize: 200,
        },
      }).then((res) => {
        console.log("PagingFreightrate", res);
        const data = res.data.content.rows.reverse();
        const cp = data.filter((n) => n.freightrateType === 0);
        const mc = data.filter((n) => n.freightrateType === 1);
        const x = cp.map((n) => {
          const arr = n.freightrateTime.split("-");
          return arr.slice(0, 2).join("-");
        });
        console.log("xxxx", x);
        const cpy = cp.map((n) => n.freightrateNumber);
        const mcy = mc.map((n) => n.freightrateNumber);
        freight.setOption({
          xAxis: [
            {
              type: "category",
              data: x,
              axisPointer: {
                type: "shadow",
              },
            },
          ],
          series: [
            {
              name: "木材",
              type: "line",
              boundaryGap: [0, 0.1],
              data: mcy,
            },
            {
              name: "成品",
              type: "line",
              boundaryGap: [0, 0.1],
              data: cpy,
            },
          ],
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
#logistics,
#freight,
#traffic {
  // width: 730px;
  height: 300px;
  margin-top: 20px;
}
.iframe-wrap {
  width: 100%;
  height: 600px;
  iframe {
    width: 92%;
    height: 100%;
    border: 0;
  }
}
</style>
